<template>
  <div class="feedback-btn" @click="openFeedbackModal">
    Members

    <ManageUsersModal
      :isModalShown="isModalShown"
      :memberId="currentUser.id"
      :hideAttachButton="true"
      @hideModal="closeFeedbackModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ActionButton from './ActionButton.vue'
import ManageUsersModal from '../admin/ManageUsersModal.vue'

export default {
  components: {
    ActionButton,
    ManageUsersModal,
  },
  data() {
    return {
      isModalShown: false,
    }
  },
  methods: {
    openFeedbackModal() {
      this.isModalShown = true
    },
    closeFeedbackModal(e) {
      e.stopPropagation()
      this.isModalShown = false
    },
  },
  computed: {
    ...mapState(['currentUser']),
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styleVars.scss';

.feedback-btn {
  position: absolute;
  bottom: 52px;
  height: 52px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  font-size: $fontSizeStandart;
  cursor: pointer;

  .modal-header {
    width: calc(100% - 80px);
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $fontColorBlack !important;

    .modal-title {
      font-size: $fontSizeModalTitle;
    }

    .close-modal-btn {
      width: 24px;
      height: 24px;
      background-size: 14px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../assets/icons/close-icon.svg');
      cursor: pointer;
    }
  }

  .modal-error {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: $redWarningColor;
    font-size: $fontSizeMinified;
  }

  .modal-content {
    width: calc(100% - 80px);
    height: 248px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .feedback-texarea {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      padding: 4px;
      box-sizing: border-box;
      font-size: $fontSizeStandart;
      outline: none;
      resize: none;
    }
  }
}
</style>
